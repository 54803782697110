import React, { useEffect, useRef, useState } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { SeverityIndicatorList } from '../../../interfaces/GermWatchTypes'

export const SeverityIndicator: React.FC<{
  data: SeverityIndicatorList | undefined
}> = ({ data }) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [chart, updateChart] = useState<Highcharts.Options>(
    {} as Highcharts.Options
  )

  useEffect(() => {
    var seasons = []
    var seriesData: Array<Highcharts.SeriesOptionsType> = []
    var opVisit = []
    var edVisit = []
    var ipVisit = []
    var icuVisit = []

    for (var i = 0; i < data!.severityIndicatorList.length; i++) {
      seasons.push(data!.severityIndicatorList[i].seasonCode)
      opVisit.push(data!.severityIndicatorList[i].opVisitCount)
      edVisit.push(data!.severityIndicatorList[i].edVisitCount)
      ipVisit.push(data!.severityIndicatorList[i].ipVisitCount)
      icuVisit.push(data!.severityIndicatorList[i].icuVisitCount)
    }
    //reverse all
    opVisit.reverse()
    edVisit.reverse()
    ipVisit.reverse()
    icuVisit.reverse()

    seriesData.push({
      type: 'column',
      name: 'OPVisit',
      data: opVisit,
    })
    seriesData.push({
      type: 'column',
      name: 'EDVisit',
      data: edVisit,
    })
    seriesData.push({
      type: 'column',
      name: 'IPVisit',
      data: ipVisit,
    })
    seriesData.push({
      type: 'column',
      name: 'ICUVisit',
      data: icuVisit,
    })

    let chart: Highcharts.Options = {
      chart: {
        type: 'column',
        height: '550px',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        //seasons
        categories: seasons,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Percentage of visits',
        },
        stackLabels: {
          style: {
            color: 'black',
          },
          enabled: true,
        },
      },
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
      },
      plotOptions: {
        column: {
          stacking: 'percent',
        },
      },
      series: seriesData,
    }

    updateChart(chart)
  }, [data])

  return (
    <>
      {!!data && (
        <HighchartsReact
          highcharts={Highcharts}
          options={chart}
          ref={chartComponentRef}
        />
      )}
    </>
  )
}
