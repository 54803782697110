import React from 'react'
import {
  IonCol,
  IonGrid,
  IonItemDivider,
  IonRow,
  IonText,
  IonTitle,
} from '@ionic/react'

import './Covid19Resources.scss'
import { headerColor } from '../../data/Constants'

export const Covid19Resources: React.FC = () => {
  const pageTitle = 'Covid-19 Resources'

  return (
    <div className="page-content ion-margin-bottom">
      <IonGrid>
        <IonRow className="ion-margin-bottom ion-align-items-left">
          <IonCol className="ion-no-padding ion-no-margin ion-float-start">
            <IonTitle className="page-title ion-no-padding">
              {pageTitle}
            </IonTitle>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonText>
              <h3 style={{ fontWeight: 500, color: `${headerColor}` }}>
                Physician Tools
              </h3>
              <IonItemDivider className="itemDivider">
                <h4
                  className="heading4"
                  style={{
                    fontWeight: 500,
                    marginTop: -5,
                    color: `${headerColor}`,
                  }}
                >
                  COVID Vaccine
                </h4>
              </IonItemDivider>
              <ul className="resource-list">
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529932641"
                  >
                    Tips for Talking With Patients about the COVID-19 Vaccine
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529988772"
                  >
                    Clinical Guidance for Pfizer Vaccine Children Ages 5 – 11
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href=" https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529985138"
                  >
                    COVID-19 Vaccine: Requests for Medical Exemption
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529861892"
                  >
                    COVID-19 vaccine Recommendations for Vaccine Scenarios​
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529861784"
                  >
                    SARS-Co-V-2 Vaccination Immunocompromised Patients
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529927229"
                  >
                    SARS-Co-V-2 J&amp;J Vaccine Induced Thrombotic
                    Thrombocytopenia (VITT)
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529861890"
                  >
                    SARS-Co-V-2 Vaccine Breast Imaging Guideline
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529861827"
                  >
                    SARS-Co-V-2 Vaccine Administration Errors and Deviations
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529860786"
                  >
                    SARS-Co-V-2 Vaccination Post-Vaccination and Adverse Events​
                  </a>
                </li>
              </ul>
            </IonText>
            <IonText>
              <h3
                style={{
                  fontWeight: 500,
                  marginTop: 25,
                  color: `${headerColor}`,
                }}
              >
                Patient Education
              </h3>
              <IonItemDivider className="itemDivider">
                <h4
                  className="heading4"
                  style={{
                    fontWeight: 500,
                    marginTop: -5,
                    color: `${headerColor}`,
                  }}
                >
                  COVID Vaccine
                </h4>
              </IonItemDivider>
              <ul className="resource-list">
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529863477"
                  >
                    COVID-19 Vaccines: Frequently asked questions (FAQs) fact
                    sheet
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529878834"
                  >
                    COVID-19 Vaccines: Frequently asked questions (FAQs) fact
                    sheet Spanish​
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529860790"
                  >
                    After Your COVID Vaccine English
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529861432"
                  >
                    After Your COVID Vaccine Spanish​
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529987963"
                  >
                    COVID-19 Vaccines: Children FAQs English
                  </a>
                </li>
                <li className="resource-list-item">
                  <a
                    className="covid-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://intermountainhealthcare.org/ckr-ext/Dcmnt?ncid=529989462"
                  >
                    COVID-19 Vaccines: Children FAQs Spanish
                  </a>
                </li>
              </ul>
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}
