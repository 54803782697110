export type ErrorDetails = {
  url: string
  params?: any
}

export const handleApiError = (error: any, details: ErrorDetails) => {
  console.error(`Error occurred err.message: ${error.message}`)
  console.error(`Details: ${JSON.stringify(details)}`)
  // TODO: Log error details to data store.
  // TODO: Email to Support list.
  // TODO: Log error to Analytics.
}

export const parseApiError = (error: any): Error => {
  const response = error?.response
  const status = response?.status || ''
  const message = response?.data?.message || ''
  const path = response?.data?.path || ''

  return response
    ? new Error(`HttpStatus: ${status}, Message: ${message}, URL: ${path}`)
    : error
}

export const hasUniqueConstraintApiError = (error: any): boolean => {
  const message: string = error?.response?.data?.message || ''

  return (
    message.includes('unique constraint') ||
    message.includes('PK_ADV_PROVIDER_NOTES')
  )
}
