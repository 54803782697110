import { IonItem, IonItemDivider, IonItemGroup, IonLabel } from '@ionic/react'
import React from 'react'
import { Organism } from '../../interfaces/GermWatchTypes'

export const PathogenList: React.FC<{
  organisms: Organism[]
  selectedPathogen: Organism
  label: string
  updatePathogenAction: (newPathogen: Organism, type: string) => void
  pathogenType: string
}> = ({
  organisms,
  selectedPathogen,
  label,
  updatePathogenAction,
  pathogenType,
}) => {
  const getLevel = (level: number) => {
    switch (level) {
      case 1:
        return 'level-5'
      case 2:
        return 'level-4'
      case 3:
        return 'level-3'
      case 4:
        return 'level-2'
      case 5:
        return 'level-1'
    }
  }
  return (
    <IonItemGroup className="pathogen-list">
      <IonItemDivider>
        <IonLabel>{label}</IonLabel>
      </IonItemDivider>
      <div className="items">
        {organisms.length > 0 &&
          organisms.map((resp, index) => {
            return (
              <IonItem
                lines="full"
                className={`${
                  selectedPathogen.organismCode === resp.organismCode
                    ? 'is-selected'
                    : ''
                }`}
                key={index}
                onClick={() => updatePathogenAction(resp, pathogenType)}
              >
                <IonLabel>
                  <div className={`trend ${getLevel(resp.activityNumber)}`}>
                    {resp.positiveCount}
                  </div>
                  {resp.organismName}
                </IonLabel>
              </IonItem>
            )
          })}
      </div>
    </IonItemGroup>
  )
}
