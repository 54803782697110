const _colorLabel_NoSSA = [220, 220, 220, 1.0] //No SSA was defined by the census
const _colorFill_NoSSA = [180, 180, 180, 1.0] //No SSA was defined by the census
const strokeColorlMin = [180, 180, 180, 1.0]
const defaultGradLabelMax = [0, 0, 0, 1.0]
export const _strokeHilightColor = [0, 0, 0, 0.65]

export const fullRed = {
  gradFillMin: [245, 245, 245, 1.0],
  gradFillMax: [255, 0, 0, 1.0],
  gradLabelMin: [140, 140, 140, 1.0],
  gradLabelMax: defaultGradLabelMax,
  gamma: 0.2,
}

export const fullBlue = {
  gradFillMin: [245, 245, 245, 1.0],
  gradFillMax: [0, 0, 255, 1.0],
  gradLabelMin: [180, 180, 180, 1.0],
  gradLabelMax: defaultGradLabelMax,
  gamma: 0.2,
}

export const darkBlue = {
  gradFillMin: [220, 220, 220, 1.0],
  gradFillMax: [0, 50, 100, 1.0],
  gradLabelMin: [255, 255, 255, 1.0],
  gradLabelMax: [100, 150, 200, 1.0],
  gamma: 0.9,
}

export const geoJsonPolyLabelColorFn = (
  pct_pctMax: number,
  gradLabelMin: number[],
  gradLabelMax: number[],
  gamma: number
) => {
  if (pct_pctMax > 0) {
    var magnitude: any = pct_pctMax / 6 //eg pct / pctMax
    return getColorModulated(gradLabelMax, gradLabelMin, 1.0 - magnitude)
  }
  return _colorLabel_NoSSA
}

//modulate src and dst color based on modulate value.
export function getColorModulated(src: number[], dst: number[], mod: number) {
  var modInv = 1.0 - mod
  return [
    modInv * src[0] + mod * dst[0],
    modInv * src[1] + mod * dst[1],
    modInv * src[2] + mod * dst[2],
    modInv * src[3] + mod * dst[3],
  ] //eg mix choropleth max color with light gray based on some magnitude (0:1).
}

//----------------------------- choropleth -----------------------------------
//pct_pctMax is an array from the csv file
export const geoJsonPolyColorFillFn = function (
  pct_pctMax: number,
  gradColorMin: number[],
  gradColorMax: number[]
) {
  if (pct_pctMax > 0 ) {
    var magnitude = pct_pctMax / 6 //eg pct-or-Activity / ActivityHigh=6
    return getColorModulated(gradColorMin, gradColorMax, magnitude)
  }
  return _colorFill_NoSSA
}
