import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios'
import {
  DiseaseUpdateList,
  EpicurveDataList,
  GermSchoolData,
  PathogenTrends,
  RespiratoryOrganismImageList,
  SeverityIndicatorList,
  MapDataList,
} from '../../interfaces/GermWatchTypes'
import { handleApiError } from './ApiUtils'
import EnvConstants from '../EnvConstants'

const baseUrl: string = EnvConstants.GERMWATCH_PHYSICIAN_API_URL

const apiKey: AxiosRequestHeaders = EnvConstants.INCLUDE_API_KEY
  ? {
      'x-api-key': EnvConstants.SERVICE_API_KEY,
    }
  : {}

const germWatchApi: AxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    accept: 'application/json',
    ...apiKey,
  },
})

const currentSeason: string =
  (new Date().getFullYear() - 1).toString() + //Previous year
  '-' +
  new Date().getFullYear().toString().substr(-2) //current year
export const getEpicurve = async (
  pathogenType: string = 'respiratory',
  pathogenCode: string = 'RSV',
  season: string = currentSeason
): Promise<EpicurveDataList> => {
  const url = `epicurve?PathogenType=${pathogenType}&PathogenCode=${pathogenCode}&Season=${season}`
  return new Promise(async (resolve, reject) => {
    try {
      let response: EpicurveDataList
      let epicurveList = (await germWatchApi.get(url)).data
      response = {
        respiratoryEpicurveDataList:
          typeof epicurveList === 'string'
            ? JSON.parse(epicurveList).respiratoryEpicurveDataList
            : epicurveList.respiratoryEpicurveDataList,
        code: pathogenCode,
        season,
      }

      resolve(response)
    } catch (e) {
      reject(e)
    }
  })
}

export const getDiseaseUpdate = async (): Promise<DiseaseUpdateList> => {
  const url = 'diseaseupdate'
  return new Promise(async (resolve, reject) => {
    try {
      const data = (await germWatchApi.get(url)).data
      if (typeof data === 'string') {
        if (data[0] === '{') {
          //The current api is returning a 200 even when there is an error. New api will fi that
          resolve(JSON.parse(data))
        } else {
          reject(data)
        }
        resolve(JSON.parse(data))
      } else {
        resolve(data)
      }
    } catch (e) {
      handleApiError(e, { url: baseUrl + '/' + url })
      reject(e)
    }
  })
}

export const getActivityMapImageList =
  async (): Promise<RespiratoryOrganismImageList> => {
    const url = 'activitymapimagelist'
    return new Promise(async (resolve, reject) => {
      try {
        let data = (await germWatchApi.get(url)).data
        typeof data === 'string' ? resolve(JSON.parse(data)) : resolve(data)
      } catch (e) {
        handleApiError(e, { url: baseUrl + '/' + url })
        reject(e)
      }
    })
  }

export const getPathogenTrends = async (): Promise<PathogenTrends> => {
  const url = 'pathogentrends'
  return new Promise(async (resolve, reject) => {
    try {
      let data = (await germWatchApi.get(url)).data
      Object.keys(data).forEach((key) => {
        if (typeof data[key] === 'string') data[key] = JSON.parse(data[key])
      })
      resolve(data)
    } catch (e) {
      handleApiError(e, { url: baseUrl + '/' + url })
      reject(e)
    }
  })
}

export const getseverityIndicator = async (
  code: string = 'RSV'
): Promise<SeverityIndicatorList> => {
  const url = `severityindicatorV2?PathogenCode=${code}`
  return new Promise(async (resolve, reject) => {
    try {
      let data = (await germWatchApi.get(url)).data
      typeof data === 'string' ? resolve(JSON.parse(data)) : resolve(data)
    } catch (e) {
      handleApiError(e, { url: baseUrl + '/' + url })
      reject(e)
    }
  })
}

export const getGermSchoolData = async (
  pathogenName: string
): Promise<GermSchoolData[]> => {
  const url = `searchgermschool?PathogenName=${pathogenName}`
  return new Promise(async (resolve, reject) => {
    try {
      let data = (await germWatchApi.get(url)).data
      resolve(data)
    } catch (e) {
      handleApiError(e, { url: baseUrl + '/' + url })
      reject(e)
    }
  })
}

export const getMapData = async (code: string, date: Date): Promise<any> => {
  const url = `mapdata?code=${code}&date=${
    date.getMonth() + 1
  }-${date.getDate()}-${date.getFullYear()}`
  return new Promise(async (resolve, reject) => {
    try {
      let data = (await germWatchApi.get(url)).data
      resolve(data)
    } catch (e) {
      handleApiError(e, { url: baseUrl + '/' + url })
      reject(e)
    }
  })
}

export const getSundayDate = (): Date => {
  let date = new Date()
  if (date.getDay() > 0)
    date = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + (date.getDay() * -1 - 7),
      23,
      59,
      59
    )
  else
    date = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 7,
      23,
      59,
      59
    )
  return date
}
