import React, { useEffect, useRef, useState } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { EpicurveDataList } from '../../../interfaces/GermWatchTypes'
import { IonText } from '@ionic/react'

export const WeeksGraph: React.FC<{ data: EpicurveDataList[] }> = ({
  data,
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [chart, updateChart] = useState<Highcharts.Options>(
    {} as Highcharts.Options
  )

  useEffect(() => {
    const seriesObject: Highcharts.SeriesOptionsType[] = []
    var seasonWks: string[] = []
    data?.forEach((edl) => {
      var datNum: number[] = []
      edl!.respiratoryEpicurveDataList.length > 0 &&
        edl!.respiratoryEpicurveDataList.forEach((epicurveData, index) => {
          seasonWks.push(epicurveData.weekStartDate.substr(5, 5))
          datNum.push(epicurveData.positiveCount)
        })
      seriesObject.push({
        name: edl?.season,
        type: 'line',
        data: datNum,
        visible: edl.isVisible,
      })
    })

    let chart: Highcharts.Options = {
      chart: {
        type: 'line',
        height: '250px',
      },

      title: {
        text: data![0]!.code || '',
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: 'PositiveCount (N)',
        },
        plotLines: [
          {
            value: 0,
            width: 1,
            color: '#808080',
          },
        ],
      },
      tooltip: {
        valueSuffix: 'N',
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 0,
      },
      xAxis: {
        categories: seasonWks,
      },
      series: seriesObject,
    }

    updateChart(chart)
  }, [data])

  return (
    <>
      {!!data ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={chart}
          ref={chartComponentRef}
          //   {...props}
        />
      ) : (
        <IonText>Loading!</IonText>
      )}
    </>
  )
}
