
/** 
 * Please don't add to this file. 
 * This file gets GENERATED automatically.
 * Please add environment specific key-DummySecretValue pairs to .env.sample file.
 * Add the correct key-value pair in .env file.
 * Please add environment specific non-secret key-value pairs to common.properties file.
 * Override them in properties/${env}.properties file.
 **/
export default class EnvConstants {

	static PROV_PAYMENT_PATH = '/pde-provider-public-app-service/payments';
	static PAYMENT_LOCATIONS_PATH = '/pde-provider-public-app-service/locations';
	static SKIP_BASENAME_IF_AT = 'https://intermountainphysician.org';
	static SERVICE_API_KEY = "Ot8kPzuaYQPK2ejcF3pGGFHBBjo0B7gL";
	static PUBLIC_URL = "";
	static PDE_API_URL = 'https://pde.intermountainhealthcare.org/api/pde/v1/services';
	static INCLUDE_API_KEY = true;
	static GERMWATCH_PHYSICIAN_API_URL = 'https://pde.intermountainhealthcare.org/api/pde/v1/services/pde-germwatch-antibiogram-service/antibiogram';

}
