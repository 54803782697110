import React, { useState, useEffect } from 'react'
import coordinates from '../../../data/GermwatchGeo/coordinates.json'
import { BaseMap } from './map/BaseMap'
import Controls from './controls/Controls'
import FullScreenControl from './controls/FullScreenControl'
import {
  IonButton,
  IonCheckbox,
  IonLabel,
  IonRow,
  IonText,
  useIonToast,
} from '@ionic/react'
import { MapDataList, Organism } from '../../../interfaces/GermWatchTypes'
import { getMapData, getSundayDate } from '../../../data/api/GermWatch'
import './InteractiveMap.scss'

export const InteractiveMap: React.FC<{ organism: Organism }> = ({
  organism,
}) => {
  const [center, setCenter] = useState<number[]>(coordinates.centerUtah)
  const [zoom, setZoom] = useState<number>(7)
  const [mapDataList, setMapDataList] = useState<MapDataList>()
  const [sunDate, setSunDate] = useState<Date>()
  const [key, setKey] = useState<string>()
  const [cache, setCache] = useState<MapDataList[]>()
  const [rotate, setRotate] = useState<boolean>()
  const [areaName, setAreaName] = useState<string>()
  const [activity, setActivity] = useState<number>()

  const firstSunDate = getSundayDate()
  const rotateDate = new Date(
    firstSunDate.getFullYear(),
    firstSunDate.getMonth(),
    firstSunDate.getDate() - 49
  )

  //Toaster
  const [present] = useIonToast()

  const setStateOfParent = (
    center: number[],
    zoom: number,
    areaName: string,
    activity: number
  ) => {
    setCenter(center)
    setZoom(zoom)
    setAreaName(areaName)
    setActivity(activity)
  }

  const getMapDataForMap = async (_disease: string, _date: Date) => {
    Promise.resolve(getMapData(_disease, _date))
      .then((response) => {
        setMapDataList(response)
        let tempLists: MapDataList[]
        let tempData: MapDataList = {
          ...response,
          date: getDate(response.weekStartDateStr),
        }
        if (cache === undefined) tempLists = [tempData]
        else {
          tempLists = cache
          tempLists.push(tempData)
        }
        setCache(tempLists)
        setKey(_disease + _date.toString())
      })
      .catch((e) => {
        console.log('error loading data: ' + e.message)
        present({
          message: 'Something failed to load. Please try again',
          duration: 3000,
          position: 'top',
          cssClass: 'custom-toaster',
          color: 'danger',
        })
      })
  }

  const getMapDataForMapPreCheck = (
    _disease: string | undefined,
    _date: Date | undefined
  ) => {
    if (!_disease || !_date) return

    const listLen = cache === undefined ? -1 : cache.length
    let dateStr = _date.toDateString()
    for (let i = 0; i < listLen; i++) {
      if (cache === undefined) continue
      let tempDate: string | undefined = ''
      if (cache[i].date != null) tempDate = cache[i].date?.toDateString()

      if (cache[i].code == _disease && dateStr === tempDate) {
        setMapDataList(cache[i])
        setKey(_disease + tempDate)
        return
      }
    }

    getMapDataForMap(_disease, _date)
  }

  const previousWeek = () => {
    let tempDate: Date = new Date()
    if (sunDate !== undefined)
      tempDate = new Date(
        sunDate.getFullYear(),
        sunDate.getMonth(),
        sunDate.getDate() - 7
      )
    else
      tempDate = new Date(
        firstSunDate.getFullYear(),
        firstSunDate.getMonth(),
        firstSunDate.getDate() - 7
      )
    setSunDate(tempDate)
    getMapDataForMapPreCheck(organism.organismCode, tempDate)
  }
  const nextWeek = () => {
    let tempDate: Date = new Date()
    if (sunDate !== undefined)
      tempDate = new Date(
        sunDate.getFullYear(),
        sunDate.getMonth(),
        sunDate.getDate() + 7
      )
    else return
    if (tempDate <= firstSunDate) {
      setSunDate(tempDate)
      getMapDataForMapPreCheck(organism.organismCode, tempDate)
    }
  }
  const firstWeek = () => {
    setSunDate(firstSunDate)
    getMapDataForMapPreCheck(organism.organismCode, firstSunDate)
  }

  const getDate = (dateStr: string) => {
    let newDate = null
    try {
      newDate = new Date(dateStr)
    } catch {}
    return newDate
  }

  let weekDate: string = 'Uknown'
  if (sunDate !== undefined) {
    weekDate = sunDate.toDateString()
    if (mapDataList !== undefined && organism.organismCode != mapDataList.code)
      getMapDataForMapPreCheck(organism.organismCode, sunDate)
  }

  useEffect(() => {
    if (rotate) {
      const timeout = setTimeout(function () {
        if (
          !sunDate ||
          (sunDate.getFullYear() == firstSunDate.getFullYear() &&
            sunDate.getMonth() == firstSunDate.getMonth() &&
            sunDate.getDate() == firstSunDate.getDate())
        ) {
          setSunDate(rotateDate)
        } else {
          nextWeek()
        }
      }, 1000)

      return () => clearTimeout(timeout)
    }
  })

  const getActivityText = (activity: number | undefined) => {
    let result = 'n/a'
    switch (activity) {
      case 1:
        result = 'None'
        break
      case 2:
        result = 'Minimal'
        break
      case 3:
        result = 'Low'
        break
      case 4:
        result = 'Moderate'
        break
      case 5:
        result = 'High'
        break
      case 6:
        result = 'Very High'
        break
    }
    return result
  }

  return (
    <div className="map-wrapper">
      <h2>
        <IonText>Organism: {organism.organismName}</IonText>
      </h2>
      <h2>
        <IonText>Week of {weekDate}</IonText>
      </h2>
      <IonRow>
        <IonButton
          size="small"
          onClick={previousWeek}
          className="interactive-buttons"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
          >
            <path d="M4 0l-4 4 4 4v-8z" transform="translate(2)" />
          </svg>{' '}
        </IonButton>
        <IonButton
          size="small"
          onClick={firstWeek}
          className="interactive-buttons"
        >
          this week
        </IonButton>
        <IonButton
          size="small"
          onClick={nextWeek}
          className="interactive-buttons"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
          >
            <path d="M0 0v8l4-4-4-4z" transform="translate(2)" />
          </svg>
        </IonButton>
        <IonCheckbox
          className="interactive-checkbox"
          checked={rotate}
          onClick={() => {
            setRotate(!rotate)
          }}
        ></IonCheckbox>
        <IonText>&nbsp;Auto-6 weeks&nbsp;</IonText>
        <img
          alt="legend"
          src={`${process.env.PUBLIC_URL}/assets/images/gw_legend.jpg`}
        />
      </IonRow>
      <IonRow>
        {areaName && (
          <>
            Selected Area: {areaName}
            <br />
            Activity: {getActivityText(activity)}
          </>
        )}
      </IonRow>
      <BaseMap
        key={key}
        center={center}
        zoom={zoom}
        data={mapDataList}
        areaName={areaName}
        activity={activity}
        zoomcb={setStateOfParent}
      >
        <Controls>
          <FullScreenControl />
        </Controls>
      </BaseMap>
    </div>
  )
}
