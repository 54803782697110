import { IonIcon } from '@ionic/react'
import { home } from 'ionicons/icons'
import React from 'react'
import { Link } from 'react-router-dom'
import { newPDEColor } from '../../data/Constants'
interface HomeButtonProps {
  pageTitle: string
}
const HomeButton: React.FC<HomeButtonProps> = ({ pageTitle }) => {
  return (
    <div style={{ fontSize: '17px', color: `${newPDEColor}` }}>
      {' '}
      <Link to="/home">
        <IonIcon
          icon={home}
          style={{ color: `${newPDEColor}`, fontSize: '22px' }}
        />
      </Link>{' '}
      / {pageTitle}
    </div>
  )
}
export default HomeButton
