import { IonCol, IonGrid, IonRow } from '@ionic/react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import React from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CARE_PROCESS_MODELS } from '../../data/Constants'
import './CareProcessModels.scss'
export const CareProcessModels: React.FC = () => {
  const pageTitle = 'Care Process Models'
  // const [expandAll, setExpandAll] = React.useState(false)
  const [data, setData] = React.useState(CARE_PROCESS_MODELS)
  // const expandAllCards = () => {
  //   setExpandAll(true)
  // }
  // const collapseAllCards = () => {
  //   setExpandAll(false)
  // }
  return (
    <div className="page-content ion-margin-bottom">
      <IonGrid class="ios">
        <IonRow className="ion-margin-bottom ion-align-items-left">
          <IonCol
            sizeXs="10"
            size="5"
            sizeSm="8"
            className="ion-no-padding ion-no-margin ion-float-start"
          >
            <h3 className="page-title">{pageTitle}</h3>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {/*----THIS IS FOR FUTURE USE---- */}
            {/* <div style={{ textAlign: 'end', marginBottom: '10px' }}>
          <IonButton
            onClick={() => expandAllCards()}
            fill="clear"
            style={{ fontSize: '12px', height: '20px', color: 'black' }}
          >
            Expand All
          </IonButton>
          <IonButton
            onClick={() => collapseAllCards()}
            fill="clear"
            style={{
              borderLeft: '1px solid black',
              fontSize: '12px',
              height: '20px',
              color: 'black',
            }}
          >
            Collapse All
          </IonButton>
        </div> */}
            {data.map((model) => {
              return (
                <Accordion key={model.groupName} className="accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h4 className="group-name">{model.groupName}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className="cpm-list">
                      {model.options.map((opt) => {
                        return (
                          <li key={opt.name}>
                            <span style={{ fontSize: '1em' }}>
                              <a
                                href={opt.value}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: 'none' }}
                                className="cpm-links"
                              >
                                ​{opt.name}
                                <ul>
                                  {opt.subOptions?.map((subOpt) => {
                                    return (
                                      <li>
                                        <span style={{ fontSize: '1em' }}>
                                          <a
                                            href={subOpt.value}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: 'none' }}
                                            className="cpm-links"
                                          >
                                            {subOpt.name}
                                          </a>
                                        </span>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </a>
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                    <div>
                      <br />
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default CareProcessModels
