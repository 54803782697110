import { IonRow, IonCard } from '@ionic/react'
import React from 'react'
import './CaregiverImage.scss'

const CaregiverImage: React.FC = () => {
  return (
    <React.Fragment>
      <div className="caregiver-img">
        <div className="image-text">
          {/* <h1 className="text-title">PHYSICIAN & APP</h1> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default CaregiverImage
