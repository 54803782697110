import {
  IonButton,
  IonCol,
  IonIcon,
  IonLoading,
  IonRow,
  IonText,
  IonTitle,
} from '@ionic/react'
import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core'
import { STATES } from '../../data/Constants'
import './ProviderPayment.scss'
import { sendProviderPayment } from '../../data/api/ProviderPaymentService'
import { alertCircle } from 'ionicons/icons'
import { getLocations } from '../../data/api/LocationsService'
import { PaymentLocation, PaymentRequest } from '../../interfaces/payment.types'
import { sleep } from '../../utils/sleep'
export const ProviderPayment: React.FC = () => {
  const errMsg = `We've encountered an issue. Please refresh the page or try again.`
  const pageTitle = 'Provider Payments'
  const [feeType, setFeeType] = useState<string>('')
  const [location, setLocation] = useState('Select Dues Location')
  const [numOfProviders, setNumOfProviders] = useState<number | undefined>()
  const [providerNames, setProviderNames] = useState<string>('')
  const [payerFNames, setPayerFNames] = useState<string>('')
  const [payerLNames, setPayerLNames] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [addressCont, setAddressCont] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [statePicked, setStatePicked] = useState('UT')
  const [zip, setZip] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [areaCode, setAreaCode] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [showAlert, setShowAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>(errMsg)
  const [loading, setLoading] = useState(true)
  const [apiError, setApiError] = useState(false)
  const [locations, setLocations] = useState<PaymentLocation[] | null>(null)

  const init = async () => {
    setLoading(true)
    try {
      await sleep(500)
      const data = await getLocations()
      setLocations(data)
    } catch (_) {
      setApiError(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const handleFeeChange = (event: string) => {
    setFeeType(event)
  }
  const handleChangeDropDown = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
    func: Function
  ) => {
    func(event.target.value)
  }
  const handleNumberInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    func: Function
  ) => {
    const re = /^[0-9\b\-]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      func(e.target.value)
    }
  }

  const handleQtyChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target
    const re = /^[1-9][0-9]?$/
    if (re.test(value)) {
      setNumOfProviders(parseInt(value))
    } else if (!value) setNumOfProviders(undefined)
  }

  const handleStringInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    func: Function
  ) => {
    const charRegExp = /^[a-zA-Z ';-]+$/
    if (e.target.value === '' || charRegExp.test(e.target.value)) {
      func(e.target.value)
    }
  }
  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const handleAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value)
  }
  const handleAddressCont = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressCont(e.target.value)
  }

  const validate = (email: string) => {
    const expression = /^[^@]+@[^@]+\.[^@]+$/;
    if (email) {
      return expression.test(String(email).toLowerCase())
    } else return false
  }

  const disabledButton = () => {
    if (
      feeType !== '' &&
      location !== 'Select Dues Location' &&
      !!numOfProviders &&
      providerNames !== '' &&
      payerFNames !== '' &&
      payerLNames !== '' &&
      address !== '' &&
      city !== '' &&
      zip !== '' &&
      zip.length === 5 &&
      email !== '' &&
      validate(email) &&
      areaCode !== '' &&
      areaCode.length === 3 &&
      phoneNumber !== '' &&
      phoneNumber.length === 7
    )
      return false

    return true
  }
  const providerNameArr: string[] = providerNames
    .split(/[;]/)
    .map((name) => {
      return name.trim()
    })
    .filter((name) => {
      return name !== ''
    })

  const getUnitPrice = () => {
    if (location && feeType) {
      const loc = locations?.find((l) => l.id === location)
      if (loc) {
        const feePrice = loc.prices.find((p) => p.feeType === feeType)
        return feePrice?.unitPrice || 0
      }
    }
    return 0
  }
  const autoTab = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= e.target.maxLength) {
      // Check if it's not the last input field
      if (parseInt(e.target.id, 10) < 4) {
        // Get the next input field
        const nextSibling = document.getElementById(
          `${parseInt(e.target.id, 10) + 1}`
        )
        if (nextSibling !== null) {
          nextSibling.focus()
        }
      }
    }
  }

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    setLoading(true)
    event.preventDefault()
    const payment: PaymentRequest = {
      locationId: location,
      feeType: feeType,
      quantity: numOfProviders || 0,
      providersNames: providerNameArr,
      payer: {
        firstName: payerFNames,
        lastName: payerLNames,
        address: address,
        address2: addressCont,
        city: city,
        state: statePicked,
        zip: zip,
        email: email,
        phoneNumber: `${areaCode}-${phoneNumber.slice(
          0,
          3
        )}-${phoneNumber.slice(3, 7)}`,
      },
    }
    try {
      const response = await sendProviderPayment(payment)
      if (response.status === 'SUCCESS') {
        window.location.href = `${response.redirectUrl}`
      } else if (response.status === 'FAILURE' || response.status === 'ERROR') {
        setErrorMessage(`${response.error}`)
        setShowAlert(true)
      }
    } catch (error) {
      setErrorMessage(errMsg)
      setShowAlert(true)
    } finally {
      setLoading(false)
    }
  }

  if (loading && !locations)
    return (
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message="Loading locations..."
      />
    )

  const RefreshButton = () => (
    <IonButton
      onClick={() => window.location.reload()}
      class="cont-button"
      fill="clear"
    >
      Refresh
    </IonButton>
  )

  return (
    <div className="page-content ion-margin-bottom">
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message="Processing payment request..."
      />
      <div className="ion-margin-bottom">
        <IonTitle className="page-title ion-no-padding">{pageTitle}</IonTitle>
        {(apiError || !locations?.length) && (
          <>
            <IonText className="page-para">
              {' '}
              We've encountered an issue. Please refresh the page or try again
              later.
            </IonText>
            <br />
            <br />
            <RefreshButton />
          </>
        )}
        {locations && locations.length > 0 && (
          <>
            <IonRow>
              <IonText className="page-para">
                If the payment is on behalf of more than one provider, input
                each provider&apos;s name separated by semi-colon in the
                &quot;Provider Names&quot; field. After, please input the
                information (name, address, etc) for the person completing the
                form.
              </IonText>
            </IonRow>
            <IonRow className="dropdown-row">
              <IonCol>
                <label className="section-title">
                  Location <span className="required">*</span>
                </label>
                <FormControl>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={location}
                    onChange={(e) => {
                      handleChangeDropDown(e, setLocation)
                      setFeeType('')
                    }}
                    title="location"
                    className="dropdown"
                    fullWidth
                  >
                    <MenuItem
                      key="location-placeholder"
                      value="Select Dues Location"
                      disabled
                      selected
                    >
                      Select Dues Location
                    </MenuItem>
                    {locations.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                {location !== 'Select Dues Location' && (
                  <IonRow>
                    <IonCol sizeLg="6" sizeXs="12">
                      <RadioGroup
                        value={feeType}
                        onChange={(e) => handleFeeChange(e.target.value)}
                      >
                        {locations
                          .find((l) => l.id === location)
                          ?.prices.map((pricing) => (
                            <FormControlLabel
                              value={pricing.feeType}
                              control={<Radio color="primary" />}
                              label={pricing.feeType}
                            />
                          ))}
                      </RadioGroup>
                    </IonCol>
                  </IonRow>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <label className="section-title">
                  For how many providers? <span className="required">*</span>
                </label>
                <input
                  value={numOfProviders ?? ''}
                  type="number"
                  maxLength={2}
                  onChange={(e) => handleQtyChange(e)}
                  className="input qty-input"
                />
                <p className="sub-title">
                  Total: ${((numOfProviders || 0) * getUnitPrice()).toFixed(2)}
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <label className="section-title">
                  Provider Names <span className="required">*</span>
                </label>
                <textarea
                  rows={4}
                  placeholder="Please enter each provider name as First Name Last Name, separated by semicolons"
                  className="provider-name-input"
                  value={providerNames}
                  onChange={(e) => handleStringInput(e, setProviderNames)}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeLg="6">
                <label className="section-title">
                  Payer First Name <span className="required">*</span>
                </label>
                <input
                  value={payerFNames}
                  type="text"
                  placeholder="Payer First Name"
                  className="input payer-fname"
                  onChange={(e) => handleStringInput(e, setPayerFNames)}
                />
              </IonCol>
              <IonCol sizeLg="6">
                <label className="section-title">
                  Payer Last Name <span className="required">*</span>
                </label>
                <input
                  value={payerLNames}
                  type="text"
                  placeholder="Payer Last Name"
                  className="input payer-lname"
                  onChange={(e) => handleStringInput(e, setPayerLNames)}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6">
                <label className="section-title">
                  Payer Billing Address <span className="required">*</span>
                </label>
                <input
                  id="1"
                  value={address}
                  type="text"
                  placeholder="Street Address or P.O. Box (Example: 12345 Example Street)"
                  className="input address"
                  onChange={(e) => {
                    handleAddress(e)
                    autoTab(e)
                  }}
                  maxLength={25}
                />
              </IonCol>
              <IonCol size="6">
                <label className="section-title">&nbsp;</label>
                <input
                  id="2"
                  value={addressCont}
                  type="text"
                  placeholder="Company, C/O, Apt., Suite, Unit, Building, Floor (Example: 3rd floor)"
                  className="input address"
                  onChange={handleAddressCont}
                  maxLength={25}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeMd="6" sizeXs="12">
                <label className="section-title">
                  City <span className="required">*</span>
                </label>
                <input
                  value={city}
                  type="text"
                  placeholder="City"
                  className="input city"
                  onChange={(e) => handleStringInput(e, setCity)}
                />
              </IonCol>
              <IonCol sizeXs="6" sizeMd="3">
                <label className="section-title">
                  State <span className="required">*</span>
                </label>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={statePicked}
                    className="state-dropdown"
                    onChange={(e) => handleChangeDropDown(e, setStatePicked)}
                    title="state"
                    fullWidth
                  >
                    {STATES.map((state, i) => {
                      return (
                        <MenuItem key={i} value={state.abbreviation}>
                          {state.abbreviation}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </IonCol>
              <IonCol sizeXs="6" sizeMd="3">
                <label className="section-title">
                  Zip Code <span className="required">*</span>
                </label>
                <input
                  value={zip}
                  type="text"
                  maxLength={5}
                  onChange={(e) => handleNumberInput(e, setZip)}
                  className={`input zipcode ${
                    zip && zip.length !== 5 ? 'error' : ''
                  }`}
                  placeholder="#####"
                />
                {zip === '' ? null : zip.length === 5 ? null : (
                  <p className="input-error-msg">
                    Please enter a valid Zip code
                  </p>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeMd="6" sizeXs="12">
                <label className="section-title">
                  Email <span className="required">*</span>
                </label>
                <input
                  value={email}
                  type="email"
                  placeholder="Email"
                  onChange={handleEmail}
                  className={`input email ${
                    email && !validate(email) ? 'error' : ''
                  }`}
                />
                {email === '' ? null : !validate(email) ? (
                  <p className="input-error-msg">
                    Please enter a valid email address
                  </p>
                ) : null}
              </IonCol>
              <IonCol sizeMd="6" sizeXs="12">
                <label className="section-title">
                  Phone <span className="required">*</span>
                </label>
                <input
                  id="3"
                  value={areaCode}
                  type="text"
                  maxLength={3}
                  onChange={(e) => {
                    handleNumberInput(e, setAreaCode)
                    autoTab(e)
                  }}
                  placeholder="###"
                  className={`input areacode ${
                    areaCode && areaCode.length !== 3 ? 'error' : ''
                  }`}
                />
                <input
                  id="4"
                  value={phoneNumber}
                  type="text"
                  maxLength={7}
                  onChange={(e) => handleNumberInput(e, setPhoneNumber)}
                  placeholder="###-####"
                  className={`input pnum ${
                    phoneNumber && phoneNumber.length !== 7 ? 'error' : ''
                  }`}
                />
                {areaCode === '' &&
                phoneNumber === '' ? null : areaCode.length !== 3 ||
                  phoneNumber.length !== 7 ? (
                  <p className="input-error-msg">
                    Please enter a valid phone number
                  </p>
                ) : null}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <p style={{ marginTop: '15px', marginBottom: '10px' }}>
                  Clicking Continue will open another page where you will be
                  able to make a payment{' '}
                </p>
                <IonButton
                  class="cont-button"
                  fill="clear"
                  disabled={disabledButton()}
                  onClick={handleSubmit}
                >
                  Continue
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}
      </div>

      {/*---Show alert dialog when error occurs--- */}
      {showAlert ? (
        <Dialog open={showAlert}>
          <DialogContent
            style={{
              border: '3px solid #e38a05',
              height: 'fit-content',
              width: '30vw',
            }}
          >
            <IonIcon
              icon={alertCircle}
              style={{
                color: '#e38a05',
                fontSize: '25px',
                display: 'inline-block',
                position: 'absolute',
                left: '10px',
              }}
            />
            <div style={{ fontSize: '18px', marginLeft: '15px' }}>
              {errorMessage}
            </div>
            <DialogActions>
              <IonButton
                onClick={() => {
                  window.location.reload()
                }}
                fill="clear"
                class="err-refresh-button"
              >
                Refresh
              </IonButton>
              <IonButton
                onClick={() => {
                  setShowAlert(false)
                }}
                class="try-again-button"
              >
                Try Again
              </IonButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : null}
    </div>
  )
}
export default ProviderPayment
