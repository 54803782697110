import axios, { AxiosRequestHeaders } from 'axios'
import { PaymentRequest } from '../../interfaces/payment.types'
import EnvConstants from '../EnvConstants'
import { handleApiError } from './ApiUtils'

export interface PaymentResponse {
  status: string
  redirectUrl?: string
  error?: string
}
export const sendProviderPayment = async (
  payment: PaymentRequest
): Promise<PaymentResponse> => {
  const providerPaymentUrl = `${EnvConstants.PDE_API_URL}${EnvConstants.PROV_PAYMENT_PATH}`
  const apiKey: AxiosRequestHeaders = EnvConstants.INCLUDE_API_KEY
    ? {
        'x-api-key': EnvConstants.SERVICE_API_KEY,
      }
    : {}
  try {
    const response = await axios.post(providerPaymentUrl, payment, {
      headers: apiKey,
    })
    return response.data
  } catch (error) {
    handleApiError(error, { url: providerPaymentUrl })
    throw error
  }
}
