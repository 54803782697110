import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
} from '@ionic/react'
import ihcPhysicianAPP from '../../media/icons/old-ih-logo.svg'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import EnvConstants from '../../data/EnvConstants'
import { Drawer, List, useMediaQuery } from '@material-ui/core'
import { chevronForward, menuOutline } from 'ionicons/icons'
import HomeButton from '../../components/HomeButton/HomeButton'
import ihLogo from '../../media/ih-logo-on-dark.png'
import ihLogoWhite from '../../media/ih-logo-white.png'
import './SkinPage.scss'
import CaregiverImage from '../../components/HomePageRS/CaregiverImage'

const SkinPage: React.FC = ({ children }) => {
  const currentYear = new Date().getFullYear()

  const [selected, setSelected] = useState('Home')
  const handleSelect = (option: string) => {
    setSelected(option)
    setIsMenuOpened(false)
  }
  const isMobile = useMediaQuery('(max-width: 834px)')
  useEffect(() => {
    const temp = localStorage.getItem('selected')
    setSelected(temp ? temp : '')
  }, [])
  const currentURL = window.location.href
  React.useEffect(() => {
    localStorage.setItem('selected', selected)
  }, [selected])
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const hideNav =
    query.get('hideNavigation')?.toLocaleLowerCase() === 'true' || false
  const pathname = window.location.pathname
  if (hideNav) {
    return (
      <IonPage id="provider-payment-container" className="page">
        <IonContent className="container">{children}</IonContent>
      </IonPage>
    )
  }
  const pageTitle = pathname.includes('payment')
    ? 'Provider Payments'
    : pathname.includes('resources')
    ? 'Covid-19 Resources'
    : pathname.includes('careprocessmodels')
    ? 'Care Process Models'
    : ''

  return (
    <IonPage id="provider-payment-container" className="page">
      <IonHeader className="page-header">
        <IonHeader></IonHeader>
        <IonGrid className="header-grid">
          <IonRow>
            <IonCol size="1" sizeXs="5" sizeMd="2">
              <a href="https://intermountainhealthcare.org/">
                <img src={ihLogo} className="ihLogo" />
              </a>
            </IonCol>
            <div
              style={{
                borderLeft: '3px solid #edebe9',
                height: '50px',
                marginTop: '10px',
                marginRight: '30px',
              }}
            ></div>
            <IonCol size="1" sizeXs="5" sizeMd="2">
              <IonTitle className="ion-no-padding" data-testid={`portal-title`}>
                <Link
                  to="/home"
                  onClick={() => setSelected('Home')}
                  style={{ textDecoration: 'none' }}
                >
                  <p className="titlepde flex-display">Physician &#38; APP</p>
                </Link>
              </IonTitle>
            </IonCol>
            {isMobile ? (
              <IonCol size="12" sizeXs="7" sizeMd="9">
                <IonButton
                  class="ion-float-end"
                  fill="clear"
                  onClick={() => setIsMenuOpened(true)}
                >
                  <IonIcon
                    icon={menuOutline}
                    style={{ color: 'white', fontSize: '30px' }}
                  />
                </IonButton>
              </IonCol>
            ) : (
              <>
                <IonCol
                  size="6"
                  style={{ marginLeft: '30px', marginTop: '20px' }}
                >
                  {/* <Link
                    to="/providers/payment"
                    style={{ textDecoration: 'none' }}
                  >
                    <IonText
                      className={`provider-payment ${
                        // selected === 'Provider Payments' &&
                        currentURL.includes('/providers/payment')
                          ? 'isActive'
                          : ''
                      }`}
                      onClick={() => handleSelect('Provider Payments')}
                    >
                      Provider Payments
                    </IonText>
                  </Link> */}
                  {/* <Link
                    to="/covid-19/resources"
                    style={{ textDecoration: 'none' }}
                  >
                    <IonText
                      className={`covid19-resource ${
                        // selected === 'Covid-19 Resources' &&
                        currentURL.includes('/covid-19/resources')
                          ? 'isActive'
                          : ''
                      }`}
                      onClick={() => handleSelect('Covid-19 Resources')}
                    >
                      Covid-19 Resources
                    </IonText>
                  </Link> */}
                  {/* <Link
                    to="/providers/careprocessmodels"
                    style={{ textDecoration: 'none' }}
                  >
                    <IonText
                      className={`cpm ${
                        // selected === 'Care Process Models' &&
                        currentURL.includes('/providers/careprocessmodels')
                          ? 'isActive'
                          : ''
                      }`}
                      onClick={() => {
                        handleSelect('Care Process Models')
                        if (
                          location.pathname === '/providers/careprocessmodels'
                        ) {
                          window.location.reload()
                        } else {
                          window.location.href = `${EnvConstants.PUBLIC_URL}/providers/careprocessmodels`
                        }
                      }}
                    >
                      Care Process Models
                    </IonText>
                  </Link> */}
                </IonCol>
                <IonCol size="1">
                  <IonButton
                    className="provider-login"
                    fill="clear"
                    href="https://intermountainhealth.sharepoint.com/sites/PHY"
                  >
                    Provider Login
                  </IonButton>
                </IonCol>
              </>
            )}
          </IonRow>
        </IonGrid>
      </IonHeader>
      {/* <IonHeader className="second-header">
        {/* <a
          href="https://intermountainhealthcare.org/"
          style={{ textDecoration: 'none' }}
        >
          <IonButton className="old-ihc" fill="clear">
            <IonIcon icon={ihcPhysicianAPP} className="page-icon" />
            <IonIcon icon={chevronForward} />
          </IonButton>
        </a> 
      </IonHeader> */}

      <IonContent className="container">
        {!pathname.includes('home') && !hideNav && pageTitle !== '' ? (
          <div className="page-content ion-padding-top">
            <HomeButton pageTitle={pageTitle} />
          </div>
        ) : (
          ''
        )}

        {children}
      </IonContent>
      <IonFooter className="footer">
        <IonGrid>
          <IonCol size="2" sizeXs="5" sizeMd="2">
            <img src={ihLogoWhite} className="ihLogoWhite" />
          </IonCol>
          <IonCol
            size="10"
            sizeXs="9"
            sizeMd="9"
            style={{ top: '-5px', marginLeft: '-10%' }}
          >
            <IonText>
              Copyright &#169;{currentYear}, Intermountain Health, All Rights
              Reserved
            </IonText>
          </IonCol>
          <IonCol size="7" sizeXs="9" sizeMd="9"></IonCol>
          <IonCol size="7" sizeXs="9" sizeMd="9"></IonCol>
        </IonGrid>
      </IonFooter>
      <Drawer
        anchor="right"
        open={isMenuOpened}
        onClose={() => setIsMenuOpened(false)}
        className="dashboard-options"
        data-testid={`dashboard-options`}
        style={{ width: 'fit-content' }}
      >
        <IonHeader
          className="ion-margin-start ion-margin-top ion-margin-bottom"
          style={{
            fontWeight: '600',
            borderBottom: '2px solid #ccc6c6',
            fontSize: '30px',
          }}
        >
          MENU
        </IonHeader>
        <List>
          <IonGrid>
            <IonRow
              style={{
                marginBottom: '25px',
                borderBottom: '2px solid #ccc6c6',
              }}
            >
              <IonCol style={{ marginLeft: '20px', marginTop: '10px' }}>
                <Link
                  to="/providers/payment"
                  style={{ textDecoration: 'none' }}
                >
                  <IonText
                    style={{
                      color: 'black',
                      fontSize: '15px',
                      marginRight: '0px',
                      fontWeight:
                        selected === 'Provider Payments' ? '900' : '400',
                    }}
                    onClick={() => handleSelect('Provider Payments')}
                  >
                    Provider Payments
                  </IonText>
                </Link>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                marginBottom: '25px',
                borderBottom: '2px solid #ccc6c6',
              }}
            >
              <IonCol
                style={{
                  marginLeft: '20px',
                  marginTop: '10px',
                  width: '200px',
                }}
              >
                <Link
                  to="/covid-19/resources"
                  style={{ textDecoration: 'none' }}
                >
                  <IonText
                    style={{
                      color: 'black',
                      fontSize: '15px',
                      marginRight: '0px',
                      fontWeight:
                        selected === 'Covid-19 Resources' ? '900' : '400',
                    }}
                    onClick={() => handleSelect('Covid-19 Resources')}
                  >
                    Covid-19 Resources
                  </IonText>
                </Link>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                marginBottom: '25px',
                borderBottom: '2px solid #ccc6c6',
              }}
            >
              <IonCol style={{ marginLeft: '20px', marginTop: '10px' }}>
                <Link
                  to="/providers/careprocessmodels"
                  style={{ textDecoration: 'none' }}
                >
                  <IonText
                    style={{
                      color: 'black',
                      fontSize: '15px',
                      fontWeight:
                        selected === 'Care Process Models' ? '900' : '400',
                    }}
                    onClick={() => {
                      handleSelect('Care Process Models')
                      if (
                        location.pathname === '/providers/careprocessmodels'
                      ) {
                        window.location.reload()
                      } else {
                        window.location.href = `${EnvConstants.PUBLIC_URL}/providers/careprocessmodels`
                      }
                    }}
                  >
                    Care Process Models
                  </IonText>
                </Link>
              </IonCol>
            </IonRow>
            <IonRow
              style={{
                marginBottom: '25px',
                borderBottom: '2px solid #ccc6c6',
              }}
            >
              <IonCol style={{ marginLeft: '20px', marginTop: '10px' }}>
                <Link to="/germwatch" style={{ textDecoration: 'none' }}>
                  <IonText
                    style={{
                      color: 'black',
                      fontSize: '15px',
                      fontWeight: selected === 'Germwatch' ? '900' : '400',
                    }}
                    onClick={() => {
                      handleSelect('Germwatch')
                      if (location.pathname === '/germwatch') {
                        window.location.reload()
                      } else {
                        window.location.href = `${EnvConstants.PUBLIC_URL}/germwatch`
                      }
                    }}
                  >
                    Germwatch
                  </IonText>
                </Link>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="ion-text-center">
                <IonButton
                  className="provider-login"
                  fill="outline"
                  href="https://intermountainhealth.sharepoint.com/sites/PHY"
                >
                  Provider Login
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </List>
      </Drawer>
    </IonPage>
  )
}

export default SkinPage
