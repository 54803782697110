import { Feature } from 'ol'
import { Style, Fill, Stroke, Text } from 'ol/style'
import {
  fullRed,
  geoJsonPolyColorFillFn,
  geoJsonPolyLabelColorFn,
  _strokeHilightColor,
} from './colors'
import RenderFeature from 'ol/render/Feature'
import Geometry from 'ol/geom/Geometry'
import { MapDataList } from '../../interfaces/GermWatchTypes'

export class MapsUtilClass {
  data: undefined | MapDataList
  selectedPlace?: RenderFeature | Feature<Geometry>
  onSelectedFeatureUpdate: (feature: RenderFeature | Feature<Geometry>) => void
  constructor(mapDataList: MapDataList) {
    this.data = mapDataList


    this.onSelectedFeatureUpdate = (
      feature: RenderFeature | Feature<Geometry>
    ) => {
      console.log('feature', feature)
    }
  }
  stringDivider(str: string, maxLen: number, spaceReplacer: string): string {
    if (str === 'Co.') return '' //is good enough for now
    if (str.length > maxLen) {
      var p = maxLen
      while (p > 0 && str[p] !== ' ' && str[p] !== '-' && str[p] !== '/') {
        p--
      }
      if (p > 0) {
        var left
        if (str.substring(p, p + 1) === '-') {
          left = str.substring(0, p + 1) + spaceReplacer
        } else if (str.substring(p, p + 1) === '/') {
          left = str.substring(0, p + 1) + spaceReplacer
        } else {
          left = str.substring(0, p) + spaceReplacer
        }
        var right = str.substring(p + 1)
        return left + this.stringDivider(right, maxLen, spaceReplacer)
      }
    }
    return str
  }

  hooks(selectedFeature: (feature: RenderFeature | Feature<Geometry>) => void) {
    this.onSelectedFeatureUpdate = selectedFeature
  }

  getFeatureValueAndMax = (
    featureId: number,
    obj: MapsUtilClass | undefined = undefined
  ) => {
    if (typeof obj === 'undefined') {
      obj = this
    }

    const pctRows = this.data?.items.filter((item) => { return item.id == featureId });
    if (pctRows !== undefined && pctRows.length > 0)
      return pctRows[0].activity
    return 0
  }

  createLabelText(feature: Feature<any>, labelColor: string | number[]) {
    var lblColorZoom = labelColor

    return new Text({
      textAlign: 'center',
      textBaseline: 'middle',
      font: '11px/1 Open Sans Condensed',
      text: this.stringDivider(feature.get('SmallAre_1'), 10, '\n'),
      fill: new Fill({ color: lblColorZoom }),
    })
  }

  //Sending the object in here, since OpenLayer will receive a function and load it(instead of actually executing the function from the class)
  getPolyStyle(_: MapsUtilClass): any {
    return (feature: Feature<Geometry>) => {
      const { gradFillMin, gradFillMax, gradLabelMin, gradLabelMax, gamma } =
        fullRed
      let feature_id = feature.get('SmallAreaN')
      let pct_pctMax = _.getFeatureValueAndMax(feature_id, _)
      let selectedFeature = this.selectedPlace?.get('SmallAreaN')
      const strokeColor =
        selectedFeature && selectedFeature === feature_id
          ? _strokeHilightColor
          : geoJsonPolyLabelColorFn(pct_pctMax, gradLabelMin, gradLabelMax, 0.9)

      let width = selectedFeature && selectedFeature === feature_id ? 1.5 : 1
      let labelColor = geoJsonPolyLabelColorFn(
        pct_pctMax,
        gradLabelMin,
        gradLabelMax,
        gamma
      )

      return new Style({
        stroke: new Stroke({
          color: strokeColor,
          width,
        }),
        fill: new Fill({
          color: geoJsonPolyColorFillFn(pct_pctMax, gradFillMin, gradFillMax),
        }),
        text: this.createLabelText(feature, labelColor),
      })
    }
  }
  getSimplePolyStyle(feature: Feature<Geometry>): Style {
    const { gradFillMin, gradFillMax, gradLabelMin, gradLabelMax, gamma } =
      fullRed
    var feature_id = feature.get('SmallAreaN')
    var pct_pctMax = this.getFeatureValueAndMax(feature_id, this)
    let selectedFeature = this.selectedPlace?.get('SmallAreaN')
    const strokeColor =
      selectedFeature && selectedFeature === feature_id
        ? _strokeHilightColor
        : geoJsonPolyLabelColorFn(pct_pctMax, gradLabelMin, gradLabelMax, 0.9)

    let width = selectedFeature && selectedFeature === feature_id ? 1.5 : 1
    let labelColor = geoJsonPolyLabelColorFn(
      pct_pctMax,
      gradLabelMin,
      gradLabelMax,
      gamma
    )

    return new Style({
      stroke: new Stroke({
        color: strokeColor,
        width,
      }),
      fill: new Fill({
        color: geoJsonPolyColorFillFn(pct_pctMax, gradFillMin, gradFillMax),
      }),
      text: this.createLabelText(feature, labelColor),
    })
  }
}
