import axios from 'axios'
import EnvConstants from '../EnvConstants'
import { handleApiError } from './ApiUtils'

const locationsUrl = `${EnvConstants.PDE_API_URL}${EnvConstants.PAYMENT_LOCATIONS_PATH}`

export const getLocations = async () => {
  const headers = {
    ...(EnvConstants.INCLUDE_API_KEY && {
      'x-api-key': EnvConstants.SERVICE_API_KEY,
    }),
  }
  try {
    const response = await axios.get(locationsUrl, { headers })
    return response.data
  } catch (error) {
    handleApiError(error, { url: locationsUrl })
    throw error
  }
}
