import React from 'react'
import './Map.scss'
import MapContext from './MapContext'
import VectorLayer from 'ol/layer/Vector'
import { Feature, Map, MapBrowserEvent, View } from 'ol'
import { MapsUtilClass } from '../../../../utils/GermwatchMap/mapsClass'
import VectorSource from 'ol/source/Vector'
import GeoJSON from 'ol/format/GeoJSON'
import coordinates from '../../../../data/GermwatchGeo/coordinates.json'
import RenderFeature from 'ol/render/Feature'
import Geometry from 'ol/geom/Geometry'
import { MapDataList } from '../../../../interfaces/GermWatchTypes'

interface Props {
  children: JSX.Element[] | JSX.Element
  zoom: number
  center: number[]
  data: MapDataList | undefined
  areaName: string | undefined
  activity: number | undefined
  zoomcb: any
}

interface FeatureProperties {
  Shapre_Area: number
  Shape_Leng: number
  SmallAre_1: string
  SmallAreaN: string
}

interface SelectedFeature {
  areaId: number | null
  areaName: string
  activity: number | null
}

interface State {
  update: number
  seledtedFeature: SelectedFeature | null
  diseaseUpdateSelected: number
}
//This HAS to be a class component since we will be using canvas and openlayers
//We are not going to be able to address clicks if this is not a class component
export class BaseMap extends React.Component<Props, State> {
  mapRef: React.MutableRefObject<HTMLInputElement>
  mapUtils: MapsUtilClass
  mapObject: Map
  layer: VectorLayer<VectorSource<any>>
  data: MapDataList
  constructor(props: Props) {
    super(props)

    this.mapRef = React.createRef() as React.MutableRefObject<HTMLInputElement>
    this.state = {
      update: 0,
      seledtedFeature: null,
      diseaseUpdateSelected: -1,
    }
    this.mapObject = {} as Map
    this.data = props.data ?? { date: null, code: "", items: [] }
    this.mapUtils = new MapsUtilClass(this.data)
    this.layer = {} as VectorLayer<VectorSource<any>>
  }

  componentDidMount() {
    let polyStyle = undefined
    if (this.mapUtils !== undefined)
      polyStyle = this.mapUtils.getPolyStyle(this.mapUtils)

    const tempStyle: any = {
      source: new VectorSource({
        features: new GeoJSON().readFeatures(coordinates),
      }),
      style: polyStyle,
    }

    this.layer = new VectorLayer(tempStyle)
    this.mapObject = new Map({
      // view: new View({ zoom: this.props.zoom, center: this.props.center }),
      view: new View({ zoom: this.props.zoom, center: this.props.center }),
      // view: new View({ zoom: 7, center: coordinates.centerUtah }),
      target: this.mapRef.current,
      layers: [this.layer],
      controls: [],
      overlays: [],
    })

    this.mapObject.on("change:size", () => {
      let view = this.mapObject.getView()
      this.props.zoomcb(view.getCenter(), view.getZoom(), this.props.areaName, this.props.activity)
    })

    // https://forum.ionicframework.com/t/generating-a-openlayers-map-as-a-component/161373/4
    // Look at openlayers.component.ts
    setTimeout(() => {
      const _ = this
      this.setState({
        ...this.state,
        update: this.state.update + 1,
      })
      this.mapObject.updateSize()
      //https://stackoverflow.com/questions/60541660/openlayers-setstate-on-select-event
      this.mapObject.on('click', (e) => _.onMouseClick(e))
    }, 500)
    return () => this.mapObject.setTarget(undefined)
  }

  onMouseClick(e: MapBrowserEvent<any> | any) {
    let coordinate = e.coordinate
    this.setState({
      ...this.state,
      update: this.state.update + 1,
    })

    let pixel = this.mapObject.getPixelFromCoordinate(coordinate)

    const _ = this
    _.mapUtils.data = _.data

    var features: (RenderFeature | Feature<Geometry>)[] = []
    this.mapObject.forEachFeatureAtPixel(pixel, function (feature) {
      features.push(feature)
    })
    if (_.data !== undefined
      && features.length > 0) {
      _.mapUtils.selectedPlace = features[0]
      const properties: FeatureProperties | any = features[0].getProperties()
      const _activity = getActivity(properties.SmallAreaN, _.data)
      if (properties.SmallAre_1 && properties.SmallAreaN) {
        _.setState({
          ..._.state,
          seledtedFeature: {
            areaId: properties.SmallAreaN,
            areaName: properties.SmallAre_1,
            activity: _activity
          },
        })
        this.props.zoomcb(this.props.center, this.props.zoom, properties.SmallAre_1, _activity)
      }

      //React is not going to be aware of these changes, but since it's happening in the canvas it doesn't matter
      // this.layer.setStyle(this.mapUtils.getPolyStyle(this.mapUtils))
      this.layer.setStyle(_.mapUtils.getPolyStyle(_.mapUtils))
    }

  }

  render() {
    this.data = this.props.data ?? { date: null, code: "", items: [] }

    this.mapUtils.data = this.data

    return (
      <div ref={this.mapRef} id="ol-map" className="ol-map">
        <MapContext.Provider value={{ map: this.mapObject }}>
          {this.props.children}
        </MapContext.Provider>
      </div>
    )
  }
}
function getActivity(areaId: any, data: MapDataList) {
  let result = null;
  try {
    for(let x: any = 0; x < data.items.length; x++) {
      if (data.items[x].id == areaId) {
        result = data.items[x].activity
        break;
      }
    }
  } catch (e: any) {
    console.log("getActivity error: " + e.message)
  }
  return result;
}
