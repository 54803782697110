import { IonCol, IonGrid, IonRow } from '@ionic/react'
import React from 'react'
import HomePageLS from '../../components/HomePageLS/HomePageLS'
import './Home.scss'
import CaregiverImage from '../../components/HomePageRS/CaregiverImage'

const Home: React.FC = () => {
  return (
    <IonGrid className="home-page">
      {/* <IonRow>
        <IonCol size="4">
          <HomePageLS />
        </IonCol>
        <IonCol size="8">
          <CaregiverImage />
        </IonCol>
      </IonRow> */}
      <HomePageLS />
    </IonGrid>
  )
}

export default Home
